@charset "UTF-8";

/* Fonts
   ========================================================================== */

// Roman
@font-face {
  font-family: "Neue Helvetica W01_i4";
  src: url("../fonts/58a5cbff-d570-4c18-a5e3-60868dc07ae8.eot?#iefix") format("eot")
}

@font-face {
  font-family: "Neue Helvetica W01";
  src: url("../fonts/58a5cbff-d570-4c18-a5e3-60868dc07ae8.eot?#iefix");
  src: url("../fonts/58a5cbff-d570-4c18-a5e3-60868dc07ae8.eot?#iefix") format("eot"),
  url("../fonts/462d508d-efc7-478f-b84e-288eeb69a160.woff2") format("woff2"),
  url("../fonts/2c056da8-4920-4e20-8c69-8a6b315458a9.woff") format("woff"),
  url("../fonts/2381d918-136d-444f-8391-db0cba6da388.ttf") format("truetype"),
  url("../fonts/d0697971-6f58-4597-942e-8beabd1adc87.svg#d0697971-6f58-4597-942e-8beabd1adc87") format("svg");
  font-weight: 400;
  font-style: italic;
}

// Bold
@font-face {
  font-family: "Neue Helvetica W01_n6";
  src: url("../fonts/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot?#iefix") format("eot")
}

@font-face {
  font-family: "Neue Helvetica W01";
  src: url("../fonts/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot?#iefix");
  src: url("../fonts/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot?#iefix") format("eot"),
  url("../fonts/3b303641-706e-4221-94c4-4fb491f4f8ef.woff2") format("woff2"),
  url("../fonts/14ff6081-326d-4dae-b778-d7afa66166fc.woff") format("woff"),
  url("../fonts/8fda1e47-19be-46c7-8d83-8d4fb35572f0.ttf") format("truetype"),
  url("../fonts/f751c8ae-1057-46d9-8d74-62592e002568.svg#f751c8ae-1057-46d9-8d74-62592e002568") format("svg");
  font-weight: 600;
  font-style: normal;
}

// Italic
@font-face {
  font-family: "Neue Helvetica W06_n4";
  src: url("../fonts/e0f8f15a-c0f6-4ddb-a86e-d0b3dd600c25.eot?#iefix") format("eot")
}

@font-face {
  font-family: "Neue Helvetica W06";
  src: url("../fonts/e0f8f15a-c0f6-4ddb-a86e-d0b3dd600c25.eot?#iefix");
  src: url("../fonts/e0f8f15a-c0f6-4ddb-a86e-d0b3dd600c25.eot?#iefix") format("eot"),
  url("../fonts/003e12ef-751e-4715-853b-9a1994d021d1.woff2") format("woff2"),
  url("../fonts/990dea59-fcdd-461d-863d-3517e48e8ed0.woff") format("woff"),
  url("../fonts/faf94d73-68f4-48a2-970f-f57530c211c9.ttf") format("truetype"),
  url("../fonts/e4b531b-d9fc-405e-88c3-1b34c22a8c50.svg#e4bb531b-d9fc-405e-88c3-1b34c22a8c50") format("svg");
  font-weight: 400;
  font-style: normal;
}