@charset "UTF-8";

/* Imports for all mixins + global project variables
   ========================================================================== */

// Colours
$color-blue: #208ec4;
$color-lgrey: #e1e1e1;
$color-mgrey: #555555;
$color-dgrey: #414142;

$color-error: #cc0000;
$color-success: #59b145;
$color-alert: #FFE696;
$color-pending: #ff8224;

// Typography
$base-font-size: 14px;
$base-line-height: 1.4;
$base-font-family: "Neue Helvetica W06", helvetica, sans-serif;
$base-font-color: $color-dgrey;

$heading-line-height: 1.2;
$heading-font-family: "Neue Helvetica W01", helvetica, sans-serif;
$heading-font-color: $color-dgrey;

$link-color: $color-blue;

// Breakpoints
$breakpoint-to-ems: true;

$breakpoints: (
    xsmall: 320px,
    small: 480px,
    medium: 768px,
    large: 992px,
    xlarge: 1140px,
    xxlarge: 1680px
);

$breakpoint-xsmall: 320px 479px;
$breakpoint-small: 480px 767px;
$breakpoint-medium: 768px 991px;
$breakpoint-large: 992px 1139px;
$breakpoint-xlarge: min-width 1140px;
