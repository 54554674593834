@charset "UTF-8";

/* Typography
   ========================================================================== */

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: 400;
  line-height: $base-line-height;
  color: $base-font-color;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and (min-device-pixel-ratio: 1.25),
  only screen and (min-resolution: 200dpi),
  only screen and (min-resolution: 1.25dppx) {
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

h1, h2, h3, h4 {
  margin-top: 0;
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  color: $heading-font-color;
  font-weight: 600;
}

h1 {
  font-size: em(30px);
}

h2 {
  font-size: em(24px);
}

h3 {
  font-size: em(22px);
}

h4 {
  font-size: em(18px);
}

a:link, a:visited, a:hover, a:focus {
  transition: color 0.2s ease-in-out;
  color: $link-color;
  text-decoration: none;
  outline: 0;
  border: 0;
}

a:hover, a:focus {
  color: $color-mgrey;
}

b, strong {
  font-family: "Neue Helvetica W01", helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
}

em, i {
  font-family: "Neue Helvetica W01", helvetica, sans-serif;
  font-weight: 400;
  font-style: italic;
}