@charset "UTF-8";

/* Calendar
   ========================================================================== */

#calendar {
  .fc-event {
    border-radius: 0;
    border: 0;

    .fc-content {
      @include clearfix;
      padding: 2px;
    }

    .fc-title,
    .tzo {
      display: block;
    }

    .fc-title {

    }

    .tzo {

    }

    &.event {
      background: $color-alert;
      color: $color-mgrey;
    }

    &.build-slot {
      &.not-started {

      }

      &.pending {
        background: $color-pending;
      }

      &.completed {
        background: $color-success;
      }

      &.cancelled {
        background: $color-error;
        text-decoration: line-through;
      }
    }

    &.available {
      &.reserved {
        background: rgba($color-pending, 0.7);
      }
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}