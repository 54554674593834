@charset "UTF-8";

/* Button functions/mixins
   ========================================================================== */

/*
 * styles element as a hollow button
 */
@mixin button() {
  display: block;
  font-family: "Neue Helvetica W01", helvetica, sans-serif;
  font-weight: 700;
  font-size: em(14px);
  text-align: center;
  padding: 3px 0;
  background: $color-blue;
  width: 100%;
  color: #fff;
  outline: none;
  text-decoration: none;
  border: 1px solid $color-blue;
  box-sizing: border-box;
  min-height: 30px;
  @content;

  &:hover,
  &:active,
  &:focus {
    background: #fff;
    color: $color-blue;
  }
}