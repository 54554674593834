@charset "UTF-8";

/* Tabs
   ========================================================================== */

.tabbed-content {
  @include clearfix;

  &.horizontal {
    .tabs {
      border-bottom: 1px solid $color-lgrey;
      margin-bottom: 20px;

      ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        display: inline-block;

        a {
          display: block;
          background: $color-lgrey;
          padding: 5px 10px;
          border-top: 1px solid $color-lgrey;
          border-left: 1px solid $color-lgrey;
          border-right: 1px solid $color-lgrey;
        }

        &.active a {
          background: #fff;
        }
      }
    }

    .tabbed-pane {

    }
  }

  &.vertical {
    .tabs {
      float: left;
      width: 20%;
      background: $color-lgrey;

      ul, li {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      ul {
        border: 1px solid $color-lgrey;
        border-right: 0;
      }

      li {

        &.active a {
          background: #fff;
          color: $color-dgrey;
          z-index: 10;
          position: relative;
          right: -1px;
        }
      }

      a {
        display: block;
        padding: 10px 20px;
        background: $color-lgrey;
      }
    }

    .tabbed-pane {
      float: left;
      width: 80%;

      .inner {
        padding: 20px;
        border: 1px solid $color-lgrey;
        min-height: 320px;
      }
    }
  }
}