table {
  width: 100%;

  th, td {
    padding: 5px 10px;
    border: 1px solid #fff;

    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }

  th {
    background: $color-mgrey;
    color: #fff;
    vertical-align: top;
  }

  tfoot td {
    padding: 0;
  }

  tr.odd td {
    background: $color-lgrey;
  }

  td {
    vertical-align: top;

    ul, li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      display: inline-block;

      &:after {
        content: ',';
      }

      &:last-child:after {
        content: none;
      }
    }
  }
}

.responsive {
  margin: 1em 0;
  min-width: map-get($breakpoints, 'xsmall');

  th {
    display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!
  }

  tr {
    &.odd td[scope="row"] {
      @include mappy-bp('medium') {
        background: $color-lgrey;
      }
    }

    &.even td[scope="row"] {
      @include mappy-bp('medium') {
        background: #fff;
      }
    }
  }

  td {
    @include clearfix;
    display: block;
    text-align: right;

    &[scope="row"] {
      background: $color-mgrey;
      text-align: left;
      font-weight: 700;

      a {
        color: #fff;
        display: block;

        @include mappy-bp('medium') {
          color: $color-dgrey;
        }

        &:hover {
          color: $color-blue;
        }
      }

      &:before {
        content: none;
      }
    }

    &:before {
      content: attr(data-th)": "; // who knew you could do this? The internet, that's who.
      font-weight: 700;
      float: left;

      @include mappy-bp('medium') {
        display: none;
      }
    }
  }

  th, td {
    @include mappy-bp('medium') {
      display: table-cell;
      text-align: left;
    }
  }
}