@charset "UTF-8";

/* Icons
   ========================================================================== */

// Font Icons
[class^="icon-"], [class*=" icon-"] {
  height: 16px;
  width: 16px;
  display: inline-block;
  fill: currentColor;
  margin: 0 15px -2px 0;
}