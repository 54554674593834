@charset "UTF-8";

/* Sidebar
   ========================================================================== */

#sidebar {
  background: $color-dgrey;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 50px;
  transition: width 0.6s;

  @include mappy-bp(large) {
    width: 200px;
  }

  .nav ul,
  .nav li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li.link a {
    padding: 10px 20px;
    display: block;
    color: #fff;
    font-size: em(16px);
    text-decoration: none;

    &:hover {
      border-left: #fff 4px solid;
      padding-left: 16px;
      color: $color-blue;
    }

    span {
      display: none;

      @include mappy-bp(large) {
        display: inline-block;
      }
    }
  }

  li.link.active a {
    border-left: #fff 4px solid;
    padding-left: 16px;
  }

  li.logo {
    padding: 10px 5px;

    @include mappy-bp(large) {
      padding: 10px 20px;
    }

    a {
      display: block;
    }

    svg {
      color: #fff;
      width: 100%;
      max-height: 100%;

      &.full {
        display: none;
        height: 65px;

        @include mappy-bp(large) {
          display: block;
        }
      }

      &.small {
        display: block;
        height: 40px;

        @include mappy-bp(large) {
          display: none;
        }
      }
    }
  }
}