.pagination {
  padding-top: 20px;

  span {
    display: inline-block;
    margin-right: 3px;

    a {
      display: inline-block;
      color: #fff;
      padding: 3px 8px;
      background: $color-mgrey;
    }
  }

  .current {
    padding: 3px 8px;
  }

  .current,
  span:hover a {
    background: $color-lgrey;
    color: $color-dgrey;
  }
}