form {
  .form-field {
    margin-bottom: 15px;

    label {
      display: inline-block;
      width: 170px;
      font-size: em(15px);
      font-weight: 700;
      margin-bottom: 3px;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      padding: 3px 8px;
      width: 288px;
      border: 1px solid $color-lgrey;
      background: $color-lgrey;

      &:focus {
        outline: 0;
        background: #fff;
      }
    }

    &.checkbox label {
      font-weight: 400;
      font-size: em(14px);
      margin-left: 5px;
    }
  }

  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul.actions {
    @include clearfix;
    width: 350px;

    li {
      margin-bottom: 10px;

      @include mappy-bp(medium) {
        @include span(2 of 4);
      }

      &:last-child {
        @include mappy-bp(medium) {
          @include span(2 of 4 last);
        }
      }
    }
  }

  .btn,
  button,
  input[type="submit"] {
    @include button {
      width: 160px;
    }
  }

  .field-errors {
    margin-top: 3px;

    li {
      color: $color-error;
    }
  }

  .checkboxes {
    @include clearfix;

    div {
      @include gallery(1 of 2);

      @include mappy-bp(medium) {
        @include gallery(1 of 4);
      }
    }

    label {
      font-weight: 400;
      line-height: 1.2;
      width: 70%;
    }

    input[type="checkbox"] {
      margin-right: 10px;
      vertical-align: top;
      margin-top: 4px;
    }
  }

  &#login-form {
    width: 310px;

    label {
      font-size: em(18px);
    }

    .checkbox label {
      font-size: em(16px);
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      padding: 5px 10px;
    }

    input[type="submit"] {
      @include button {
        padding: 5px 0;
        font-size: em(16px);
      }
    }
  }

  &[name="email_form"] {
    .form-field {
      width: 45%;
      margin-right: 5%;
      float: left;
    }
  }
}