@charset "UTF-8";

/* Loading
   ========================================================================== */

@keyframes ring-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-wrap {
  position: relative;

  .loading {
    position: absolute;
    display: none;
    z-index: 100;
    background: rgba(#fff, 0.6);
    width: 50%;
    height: 50%;
    padding: 25%;
  }
  .loading > div {
    display: block;
    margin: auto;
    width: 160px;
    height: 160px;
    border-radius: 80px;
    box-shadow: 0 6px 0 0 #59ebff;
    animation: ring-anim 1s linear infinite;
  }
}