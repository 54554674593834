@charset "UTF-8";

/* Content
   ========================================================================== */

#content {
  padding-left: 50px;
  transition: all 0.6s;

  @include mappy-bp(large) {
    padding-left: 200px;
  }

  #messages {
    border: 1px solid $color-alert;
    padding: 10px;
    background: rgba($color-alert, 0.4);
    margin-bottom: 15px;

    &.flash-success {
      border-color: $color-success;
      background: rgba($color-success, 0.4);
    }
  }

  #main {
    padding: 15px 20px;

    @include mappy-bp(large) {
      padding-top: 25px;
    }
  }

  #content-top {
    @include clearfix;

    h2 {
      @include mappy-bp(medium) {
        @include span(1 of 4);
      }
    }

    .page-links {
      @include mappy-bp(medium) {
        @include span(3 of 4 last);
      }
    }
  }

  .page-links {
    text-align: right;
    margin-bottom: 10px;

    ul, li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      display: inline-block;

      &:before {
        content: "|";
        position: relative;
      }

      &:first-child:before {
        content: none;
      }

      &:last-child a {
        margin-right: 0;
      }

      a {
        display: inline-block;
        margin: 0 3px;
      }
    }

    &.bottom {
      margin-top: 10px;
    }
  }

  #iframe-wrap {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid $color-mgrey;
  }

  #editor-iframe {
    border: 0;
    width: 100%;
    height: 1200px;
  }

  span.label {
    font-weight: 700;
  }

  #template-preview,
  #design-preview {
    background: $color-lgrey;
    margin-bottom: 20px;
    padding: 15px;

    .item {
      @include clearfix;
    }
  }

  #template-preview {
    display: inline-block;
    width: 180px;
    height: 240px;
  }

  #design-preview {
    height: 340px;
    overflow: auto;

    .item-wrap {
      border: 0;
    }

    .item {
      border: 0;
      background: #fff;
      text-align: center;
      padding:15px;
    }

    img {
      @include img-responsive;

      @include mappy-bp(medium) {
        @include span(2 of 4);
      }
    }

    .details {
      text-align: center;

      @include mappy-bp(medium) {
        @include span(2 of 4 last);
        text-align: left;
      }
    }
  }

  #campaign-pages,
  #campaign-emails {
    .add-item,
    .item-wrap {
      min-height: 200px;
    }

    .add-item {
      text-align: center;
      font-size: em(20px);

      svg {
        display: block;
        width: 25%;
        height: 25%;
        max-width: 50px;
        margin: auto;
      }
    }
  }
}

.hidden {
  @include visuallyhidden;
}