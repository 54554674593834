@charset "UTF-8";

/* Header
   ========================================================================== */

#top {
  @include clearfix;
  background: $color-lgrey;
  padding: 15px 20px 15px;

  h1 {
    @include span(2 of 6);
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;
    font-size: em(26px);
  }

  .account-links {
    @include span(4 of 6 last);
    text-align: right;

    ul, li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      display: inline-block;
      margin-left: 20px;

      &.logged-in-as {
        display: none;

        @include mappy-bp(medium) {
          display: inline-block;
        }
      }

      &.active a {
        color: $color-mgrey;
      }

      a {
        text-decoration: none;

        &:hover {
          color: $color-mgrey;
        }

        span {
          display: none;

          @include mappy-bp(medium) {
            display: inline-block;
          }
        }
      }
    }

    svg {
      margin-right: 5px;
    }
  }
}