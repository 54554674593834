@charset "UTF-8";

/* Grid
   ========================================================================== */

$susy: (
    columns: 12,
    gutters: (1/6)
);

body {
  min-width: 400px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  @include clearfix;

  @include container(map-get($breakpoints, small));

  @include mappy-bp(medium large) {
    @include container(map-get($breakpoints, medium));
    padding: 0;
  }

  @include mappy-bp(large) {
    @include container(map-get($breakpoints, large));
    padding: 0;
  }

  @include mappy-bp(xlarge) {
    @include container(map-get($breakpoints, xlarge));
  }
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.grid {
  @include clearfix;

  .item-wrap {
    border: 1px solid $color-lgrey;
    margin-bottom: 20px;

    @include mappy-bp(medium) {
      @include gallery(2 of 4);
    }

    @include mappy-bp(xlarge) {
      @include gallery(2 of 6);
    }
  }

  .item {
    padding: 10px;

    ul, li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      display: inline-block;

      &:after {
        content: ',';
      }

      &:last-child:after {
        content: none;
      }
    }
  }
}